<template>
  <v-card :dark="$dark.get()">
    <loader v-if="loading" />
    <v-card-title class="header">
      Работа с товарами / редактирование цен и остатков на
      филиалах</v-card-title
    >
    <v-card-text class="pa-0">
      <section v-if="product" class="top-box">
        <div class="top-box_left">
          <div class="product-name">
            {{ product.name }}
          </div>
          <!-- действия с филиалами -->
          <div class="actions">
            <p class="actions_text">
              Чтобы добавить новый филиал на котором вы хотите начать продажи
              выберите один из списка ниже и нажмите кнопку добавить, затем
              заполните данные о ценах и остатках
              <v-icon color="error" x-small>mdi-asterisk</v-icon>
            </p>
            <div class="actions_box">
              <v-select
                v-model="newDepartment"
                label="Филиалы"
                :items="notSalesDepartments"
                item-text="name"
                return-object
                solo
                hide-details
                class="actions_select"
              ></v-select>
              <v-btn text @click="addDepartment()"
                ><v-icon color="#5d4bd0" class="mr-2">mdi-plus-circle</v-icon
                >Добавить филиал
              </v-btn>
            </div>
          </div>
        </div>
        <div class="top-box_right">
          <img
            v-if="product.images.length"
            :src="product.images[0].fileUrl"
            :alt="product.images[0].alt"
            class="top-box_img"
          />
          <p v-else>Нет фото</p>
        </div>
      </section>

      <v-simple-table class="table">
        <thead>
          <tr class="table_header">
            <th>Филиал</th>
            <th>В продаже/снят с продажы</th>
            <th>Остатки</th>
            <th>Цена</th>
            <th>Цена со скидкой</th>
            <th>Скидка в %</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr class="other">
            <td>Все филиалы</td>
            <td v-for="item in all" :key="item.name">
              <div v-if="item.name !== 'available'" class="cell">
                <v-text-field
                  v-model.number="item.value"
                  outlined
                  hide-details
                  class="input"
                />
                <v-checkbox v-model="item.check"></v-checkbox>
              </div>
              <v-checkbox v-else v-model="item.check"></v-checkbox>
            </td>
            <td>%</td>
            <td>
              <v-btn small color="#5d4bd0" dark @click="changeAllPrices()"
                >Принять изменения</v-btn
              >
            </td>
          </tr>
          <tr v-for="item in prices" :key="item.departmentId">
            <td>{{ item.departmentName }}</td>
            <td><v-checkbox v-model="item.available"></v-checkbox></td>
            <td>
              <v-text-field
                v-model.number="item.balance"
                outlined
                hide-details
                class="input"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="item.price"
                outlined
                hide-details
                class="input"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="item.discountPrice"
                outlined
                hide-details
                class="input"
              />
            </td>
            <td>{{ item.discountPercent || 0 }}%</td>
            <td>
              <v-btn small color="#5d4bd0" dark @click="changePrices(item)"
                >Принять изменения</v-btn
              >
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import loader from "@/components/Loader";
import showMessage from "@/Functions/message";
import api from "@/api";

export default {
  components: {
    loader,
  },
  data() {
    return {
      loading: false,
      productId: 0,
      providerId: 0,
      product: null,
      prices: null,
      newDepartment: null,
      all: [
        {
          name: "available",
          check: false,
        },
        {
          name: "balance",
          check: false,
          value: 0,
        },
        {
          name: "price",
          check: false,
          value: 0,
        },
        {
          name: "discountPrice",
          check: false,
          value: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      USER: "User/STATE",
    }),
    // возвращает департаменты для выбора на которых не ведутся продажи
    notSalesDepartments() {
      let arr = [];
      if (this.prices && this.USER.userInfo.departments) {
        const pricesIds = this.prices.map((item) => item.departmentId);
        arr = this.USER.userInfo.departments.filter(
          (dep) => !pricesIds.includes(dep.id)
        );
      }
      return arr;
    },
  },
  watch: {},
  mounted() {
    const ids = this.$route.params.id.split("-");
    this.productId = ids[0];
    this.providerId = ids[1];
    this.setProduct();
  },
  methods: {
    ...mapActions({
      getProduct: "Products/GET_PRODUCT",
    }),
    async setProduct() {
      this.loading = true;
      let response = await this.getProduct(this.productId);
      this.product = response;
      this.prices = response.pricesList.filter(
        (item) => item.providerId === +this.providerId
      );
      this.loading = false;
    },
    async changeAllPrices() {
      const checked = this.all.filter((item) => item.check);
      if (!checked.length) {
        return;
      }
      this.prices.forEach((element) => {
        const balance = checked.find((item) => item.name === "balance");
        balance ? (element.balance = balance.value) : null;
        const price = checked.find((item) => item.name === "price");
        price ? (element.price = price.value) : null;
        const available = checked.find((item) => item.name === "available");
        available ? (element.available = available.check) : null;
        const discountPrice = checked.find(
          (item) => item.name === "discountPrice"
        );
        discountPrice ? (element.discountPrice = discountPrice.value) : null;
        this.changePrices(element);
      });
    },
    async changePrices(data) {
      this.loading = true;
      const request = {
        departmentId: data.departmentId,
        providerId: data.providerId,
      };
      const obj = {
        balance: data.balance,
        price: data.price,
        available: data.available,
        discountPrice: data.discountPrice,
        productId: this.productId,
      };
      request.body = [obj];
      let response = await api.Products.changeProductPrices(request);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Цены успешно изменены", true);
      }
      this.loading = false;
    },
    redirectToProducts(id) {
      this.$router.push("providers-list/" + id);
    },
    addDepartment() {
      if (!this.newDepartment) {
        showMessage(
          "Выберите филиал из списка, а затем нажмите кнопку добавить"
        );
        return;
      }
      const newDep = {
        available: false,
        balance: 0,
        departmentId: this.newDepartment.id,
        departmentName: this.newDepartment.name,
        discountPercent: 0,
        discountPrice: 0,
        price: 0,
        providerId: this.providerId,
      };
      this.prices.push(newDep);
    },
  },
};
</script>

<style lang="scss">
.header {
  background: linear-gradient(to right, #e93e7b 50%, white 70%);
  margin-left: -12px;
  color: white;
  padding: 5px 10px;
}
.top-box {
  display: flex;
  gap: 30px;
  width: 100%;
  align-items: center;
  &_right {
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    margin-right: 20px;
  }
  &_img {
    max-height: 200px;
  }
}

.product-name {
  font-size: 24px;
  font-weight: 700;
  color: #4395d5;
}
.input {
  max-width: 100px;
  background-color: #c9c5c5;
  border-radius: 10px;
  .v-input__slot {
    min-height: 15px !important;
  }
}
.actions {
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
  &_box {
    display: flex;
    gap: 50px;
    align-items: center;
  }
  &_select {
    max-width: 350px;
    border: #51c8f0 2px solid;
    border-radius: 15px;
  }
}

.cell {
  display: flex;
  align-items: center;
}
.table {
  margin-top: 20px;
  &_header {
    background: linear-gradient(to right, #5d4bd0, #e93e7b);
  }
  th {
    background: none !important;
    color: white !important;
  }
  td {
    border-bottom: solid 1px #e93e7a45 !important;
  }
}
.other {
  td {
    color: black;
    font-weight: 700;
  }
}
</style>
